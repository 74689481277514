<template>
  <div class="container">
    <monthly-plan
      v-for="month of months"
      v-bind:key="month"
      :ref="monthReference(month)"
      :current-month="month"
      :loading="loading"
      :leaders-list="leadersList"
    />
  </div>
</template>

<script>
import isPast from 'date-fns/isPast'
import format from 'date-fns/format'
import addMonths from 'date-fns/addMonths'
const MonthlyPlan = () => import(/* webpackPrefetch: true */ './MonthlyPlan')

export default {
  name: 'planification-form',
  components: {
    MonthlyPlan,
  },
  props: {
    leadersList: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      months: [],
    }
  },
  computed: {
    notValid () {
      const refs = Object.values(this.$refs).map(r => r[0])
      return refs.some(r => !r.formReady)
    },
  },
  created () {
    const today = new Date()
    let initial = new Date(today.getFullYear(), 9)
    if (isPast(initial)) {
      // TEMPORARY FIX ME
      // initial.setFullYear(today.getFullYear() + 1)
    }

    const months = []
    months.push(format(initial, 'MM/dd/yyyy'))
    for (let i = 0; i < 11; i++) {
      initial = addMonths(initial, '+1')
      months.push(format(initial, 'MM/dd/yyyy'))
    }
    this.months = months.slice(0)
  },
  methods: {
    monthReference (month) {
      return month.replace(/\/+/g, '-') + 'Ref'
    },
    submit () {
      const planning = []
      for (const month of this.months) {
        const ref = this.$refs[this.monthReference(month)][0]
        const plan = Object.assign({}, ref.getPlanning())
        plan.date = month
        plan.presentations = Number(plan.presentations)
        planning.push(plan)
      }
      this.$emit('submit', planning)
    },
  },
}
</script>
